<template>
  <Toast />
  <div class="grid">
    <div class="col-12">
        <div class="card">
          <div class="containerClass">
                <OrganizationTopBar/>
            </div>
        </div>
      <Card>
        <template #title> Instrucciones del widget </template>
        <template #content>
          <p>
            Copia el siguiente fragmento de código HTML en tu sitio web para mostrar el widget de reservas
          </p>
          <div class="flex align-items-center">
            <code class="mr-1">{{ widgetCode }}</code>
            <Button
              icon="pi pi-copy"
              @click="copyCode()"
              class="p-button-rounded p-button-success"
            />
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title> Avanzado </template>
        <template #content>
          <div v-if="customCss !== null">
            <div class="field col-12">
              <span class="p-float-label">
                <Textarea
                  inputId="textarea"
                  rows="10"
                  :autoResize="true"
                  class="w-full"
                  v-model="customCss"
                ></Textarea>
                <label for="textarea">CSS personalizado</label>
              </span>
            </div>
          </div>
          <Skeleton width="100%" height="12rem" v-else></Skeleton>
        </template>
        <template #footer>
          <Button
            icon="pi pi-save"
            @click="saveCustomCss()"
            label="Guardar"
            v-if="customCss !== null"
          />
          <Skeleton width="8rem" height="3rem" v-else></Skeleton>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import OrganizationService from "../service/OrganizationService";
import {formWidgetSnippet} from "../utils/util";
import OrganizationTopBar from './OrganizationTopbar.vue';

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService.getConfig(this.$route.params.id).then((config) => {
      this.customCss = config.bookingConfig.bookingFormConfig.customCss;
    });
  },
  data() {
    return {
      organizationService: null,
      widgetCode: formWidgetSnippet(process.env.VUE_APP_BASE_URL, this.$route.params.id),
      customCss: null,
    };
  },
  methods: {
    async saveCustomCss() {
      await this.organizationService.updateCustomCss(
        this.$route.params.id,
        this.customCss
      );
      this.$toast.add({
        severity: "success",
        summary: "Css personalizado actualizado",
        life: 3000,
      });
    },
    copyCode() {
      navigator.clipboard.writeText(this.widgetCode);
      this.$toast.add({
        severity: "info",
        summary: "Código copiado al portapapeles",
        life: 3000,
      });
    },
  },
  components: {
    OrganizationTopBar: OrganizationTopBar,
  },
};
</script>

<style lang="scss" scoped>
code {
  background: #22c55e;
  color: white;
  padding: 0.7rem;
  border-radius: 1rem;
}
</style>